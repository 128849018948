.container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: #f9fafc;
  min-width: 1420px;
  overflow-x: auto;
}

.sideBar {
  display: flex;
  flex-direction: column;
  border: 1px solid #e5ebf3;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 732px;
  width: 220px;
  background-color: #ffffff;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.1);
}

.logo {
  margin: 32px 0px;
  width: 140px;
  height: 43px;
  cursor: pointer;
}

.navBar {
  height: 527px;
  width: 100%;
}

.nav {
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  background-color: #f9fafc;
  border-right: 8px solid #9a4dff;
  cursor: pointer;
}

.user {
  display: flex;
  justify-content: space-between;
}

.environmentSetting {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  cursor: pointer;
}

.welcome {
  padding: 32px 16px;
  font-size: 14px;
}

.main {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  justify-content: flex-start;
  width: 1180px;
  height: 732px;
}

.basicSetting {
  display: flex;
  padding: 32px 16px;
  justify-content: right;
}

.setting {
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  background-color: #e5ebf3;
  margin-left: 16px;
  border-radius: 5px;
}

.connect {
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  margin-left: 16px;
  border-radius: 5px;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 16px;
  padding-bottom: 16px;
  border-bottom: 2px solid #e5ebf3;
}

.title {
  font-size: 36px;
  font-weight: bold;
}

.button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  background-color: #11192c;
  color: #ffffff;
  border-radius: 5px;
  height: 34px;
  cursor: pointer;
}

.surveys {
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-left: 0;
}

.descriptions {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #1c0f24;
  margin-bottom: 16px;
  position: relative;
  padding-left: 16px;
}

.description2 {
  position: absolute;
  left: 464px;
}

.description3 {
  position: absolute;
  left: 718px;
}

.surveyBox {
  display: flex;
  padding: 32px 16px;
  background-color: #ffffff;
  margin-bottom: 16px;
  border-radius: 10px;
  border: 1px solid #e5ebf3;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 16px;
  align-items: center;
  cursor: pointer;
}

.surveyTitle {
  font-weight: bold;
}
.surveyDate {
  position: absolute;
  left: 464px;
}

.surveyStatus {
  position: absolute;
  left: 704px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.surveyEdit {
  position: absolute;
  right: 150px;
  background-color: #e5ebf3;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.surveyDelete {
  position: absolute;
  right: 76px;
  background-color: #1c0f24;
  padding: 8px 16px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
  margin-left: 8px;
  margin-right: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5ebf3;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #9a4dff;
}

input:checked + .slider:before {
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.settingModal {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

select {
  width: 100%;
  height: 40px;
  margin-top: 32px;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 120px;
}

.btnButton {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  background-color: #1c0f24;
  color: #ffffff;
  border-radius: 5px;
  height: 34px;
  cursor: pointer;
  margin-left: 8px;
}

.btnButton2 {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  background-color: #e5ebf3;
  color: #1c0f24;
  border-radius: 5px;
  height: 34px;
  cursor: pointer;
  margin-left: 8px;
}
