* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #1c0f24;
}

body {
  display: flex;
  justify-content: center;
  min-height: 732px;
  min-width: 1420px;
  overflow-y: auto;
  overflow-x: auto;
}

a {
  text-decoration: none;
  color: #1c0f24;
}

button {
  border: none;
}

/* Salesmap 페이지일 때만 적용되는 스타일 */
body.salesmap-page {
  display: block;
  min-width: initial;
  width: 100%;
}
