.surveys {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin-right: 20px;
  height: 642px;
  overflow-y: auto;
  padding: 32px 16px;
}

.survey {
  display: flex;
  justify-content: default;
  align-items: center;
  border: 2px solid #e5ebf3;
  background-color: #ffffff;
  margin-bottom: 16px;
  border-radius: 10px;
}

.surveyNum {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #f2f3f6;
  height: 100%;
  width: 56px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.surveyContents {
  display: flex;
  flex-direction: column;
  margin: 16px;
  justify-content: left;
  width: 100%;
  cursor: pointer;
}

.surveyStep {
  font-size: 16px;
  font-weight: bold;
}

.stepType {
  margin-top: 16px;
  font-size: 14px;
}

.stepToggle {
  display: flex;
  align-items: center;
}

.label {
  font-size: 14px;
  white-space: nowrap;
}

.toggle {
  width: 48px;
  height: 22px;
  background-color: #e5ebf3;
  margin: 16px;
  border-radius: 30px;
  cursor: pointer;
}

.active {
  background-color: #11192c;
}

.stepDelete {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  cursor: pointer;
  height: 100%;
  border-left: 2px solid #e5ebf3;
  white-space: nowrap;
}

.addSurvey {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}

.addSurveyButton {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  background-color: #1c0f24;
  color: #ffffff;
  border-radius: 5px;
  height: 34px;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modalContent {
  background: white;
  padding: 32px 16px;
  border-radius: 5px;
}

.modalTitle {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 16px;
}

.modalTypes {
  display: flex;
  flex-direction: column;
}

.modalType {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 16px;
  background-color: #e5ebf3;
  width: 300px;
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 5px;
  cursor: pointer;
}

.modalBottom {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.modalClose {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  background-color: #1c0f24;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.modalType:hover {
  background-color: #1c0f24;
  color: white;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
  margin-left: 8px;
  margin-right: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5ebf3;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #9a4dff;
}

input:checked + .slider:before {
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
