.calculatorContainer {
  padding: 20px;
  max-width: 1800px;
  margin: 0 auto;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.layout {
  display: flex;
  gap: 20px;
  min-height: 90vh;
}

.leftPanel {
  flex: 0 0 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rightPanel {
  flex: 1;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.controlCard {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.controlTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.formGroup {
  margin-bottom: 16px;
}

.formLabel {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.formSelect {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.formSelect:focus {
  outline: none;
  border-color: #0066cc;
}

.chartContainer {
  width: 100%;
  height: 800px;
  overflow: auto;
}

@media (max-width: 1024px) {
  .layout {
    flex-direction: column;
  }

  .leftPanel {
    flex: none;
    width: 100%;
  }
}
