.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #f9fafc;
  min-width: 1420px;
  overflow-x: auto;
}

.logo {
  width: 460px;
  font-size: 64px;
  font-weight: bold;
  text-align: center;
}

.form {
  width: 460px;
  margin-top: 36px;
}

.form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.form form input {
  width: 100%;
  height: 50px;
  margin-bottom: 16px;
  padding: 0 20px;
  border: 2px solid #e5ebf3;
  border-radius: 5px;
  font-size: 16px;
}

.form form button {
  width: 100%;
  height: 50px;
  background-color: #9a4dff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 16px;
}

.footer {
  margin-top: 32px;
  font-size: 16px;
  cursor: pointer;
}
