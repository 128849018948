.container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: #f9fafc;
  min-width: 1420px;
  height: 732px;
  overflow-x: auto;
  flex-direction: column;
}
.header {
  background-color: #ffffff;
  padding: 16px;
  width: 100%;
  display: flex;
  border: 1px solid #e5ebf3;
  justify-content: space-between;
}

.headerPart {
  display: flex;
  align-items: center;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.bigButton {
  font-size: 16px;
  font-weight: bold;
  background-color: #e5ebf3;
  color: #6c7b90;
  padding: 16px;
  border-radius: 5px;
  margin-right: 16px;
  cursor: pointer;
}

.bigButtonBlack {
  font-size: 16px;
  font-weight: bold;
  background-color: #1c0f24;
  color: #ffffff;
  padding: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.main {
  display: flex;
}
.choice {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin-right: 20px;
  border-right: 1px solid #e5ebf3;
  height: 642px;
}

.settings {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
  border: 1px solid #e5ebf3;
  background-color: #ffffff;
}

.setting {
  font-size: 16px;
  padding: 0 16px;
  font-weight: bold;
  color: #6c7b90;
  cursor: pointer;
  padding-bottom: 16px;
}

.display {
  display: flex;
  flex-direction: column;
  width: 700px;
  justify-content: center;
}

.website {
  display: flex;
  flex-direction: column;
  background-color: #f2f3f6;
  height: 518px;
  margin-right: 16px;
  margin-top: 32px;
  border-radius: 10px;
  margin-bottom: 16px;
}

.top {
  display: flex;
  align-items: center;
  padding: 16px 32px;
}

.macButton {
  height: 16px;
}

.yourWeb {
  font-size: 16px;
  font-weight: bold;
  margin-left: 16px;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  padding-bottom: 16px;
}

.active {
  border-bottom: 3px solid #9a4dff;
  color: #1c0f24;
}

.isDeploy {
  display: flex;
  align-items: center;
  padding: 16px 32px;
}

.isDeployCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: 8px;
}

.greenCircle {
  background-color: #12b981;
}

.redCircle {
  background-color: #f59e0c;
}

.greenText {
  color: #12b981;
  font-weight: bold;
}

.redText {
  color: #f59e0c;
  font-weight: bold;
}

.titleInput {
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #e5ebf3;
  border-radius: 5px;
  padding: 8px;
}
