.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modalContent {
  background: white;
  padding: 16px;
  border-radius: 5px;
  width: 668px;
  height: 675px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  font-size: 36px;
  font-weight: bold;
  padding-top: 16px;
  margin-bottom: 32px;
  flex-shrink: 0;
}

.triggerInputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 17px;
  box-sizing: border-box;
}

.inputTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  flex-shrink: 0;
}

.input {
  width: 100%;
  border: 2px solid #e5ebf3;
  border-radius: 5px;
  padding: 8px;
  font-size: 14px;
  margin-bottom: 16px;
  box-sizing: border-box;
  min-height: 36px;
  flex-shrink: 0;
}

.explain {
  font-size: 14px;
  padding: 8px 0;
  margin-bottom: 16px;
  flex-shrink: 0;
}

.triggerTypes {
  display: flex;
  justify-content: space-between;
  padding: 2.5px;
  margin-bottom: 16px;
  background-color: #e5ebf3;
  border-radius: 5px;
  flex-shrink: 0;
}

.triggerType {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 150px;
  background-color: #ffffff;
  margin: 2.5px;
  border-radius: 5px;
  min-height: 40px;
}

.pageType {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 100%;
  background-color: #ffffff;
  margin: 2.5px;
  border-radius: 5px;
  min-height: 40px;
}

.triggerType:hover {
  background-color: #1c0f24;
  color: #ffffff;
  cursor: pointer;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding-right: 16px;
  flex-shrink: 0;
}

.modalButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 8px 16px;
  background-color: #1c0f24;
  color: #ffffff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 16px;
}

.selected {
  background-color: #1c0f24;
  color: #ffffff;
}

.triggerDescription {
  margin-bottom: 16px;
  padding: 2.5px;
  border-radius: 5px;
  font-size: 16px;
}

.pageTypeDescription {
  margin-bottom: 16px;
  padding: 2.5px;
  border-radius: 5px;
  font-size: 16px;
}
