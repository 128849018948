.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.input {
  width: 100%;
  height: 40px;
  border: 2px solid #e5ebf3;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 24px;
  font-size: 14px;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
}

.button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  background-color: #1c0f24;
  color: #ffffff;
  border-radius: 5px;
  height: 34px;
  cursor: pointer;
  margin-left: 8px;
}

.leftBtn {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  background-color: #e5ebf3;
  color: #6c7b90;
  border-radius: 5px;
  height: 34px;
  cursor: pointer;
  margin-left: 8px;
}

.types {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 8px;
  background-color: #e5ebf3;
  border-radius: 5px;
}

.type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  padding: 8px;
  border-radius: 5px;
  background-color: #ffffff;
}

.type:hover {
  background-color: #1c0f24;
  color: #ffffff;
}

.selected {
  background-color: #1c0f24;
  color: #ffffff;
}
