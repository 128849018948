.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

.input {
  width: 100%;
  height: 40px;
  border: 2px solid #e5ebf3;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 24px;
  font-size: 14px;
}

.optionBox {
  display: flex;
  flex-direction: row;
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c0f24;
  width: 50px;
  font-size: 14px;
  height: 40px;
  cursor: pointer;
  margin-left: 8px;
  padding: 8px;
  white-space: nowrap;
}

.addOption {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background-color: #e5ebf3;
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

.addOption:hover {
  background-color: #e5ebf3;
}

.bottom {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
}

.button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  background-color: #1c0f24;
  color: #ffffff;
  border-radius: 5px;
  height: 34px;
  cursor: pointer;
  margin-left: 8px;
}

.leftBtn {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  background-color: #e5ebf3;
  color: #6c7b90;
  border-radius: 5px;
  height: 34px;
  cursor: pointer;
  margin-left: 8px;
}

.optionLabel {
  font-size: 16px;
  margin-bottom: 8px;
}

.action {
  display: flex;
  margin-bottom: 16px;
  margin-top: 8px;
  width: 100%;
  height: 30px;
  border-radius: 5px;
}

.warningBubble {
  background-color: #ffcccb;
  color: #d8000c;
  padding: 8px;
  border-radius: 5px;
  margin-top: 8px;
  font-size: 12px;
}

.warningText {
  color: #d8000c;
  margin-left: 8px;
}
